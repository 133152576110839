<template>
  <div class="">
    <nav-bar id="home" />

    <advantages2 id="information" />

    <our-services id="services" />

    <!-- <main-header2 /> -->
    <some-of-designs2 />
    <packages2 id="packages" />
    <!-- <marketers id="marketers" /> -->

    <contact-us2 id="support" />

    <Footer />
  </div>
</template>
<script>
import NavBar from "@/components/NavBar2.vue";
import Packages2 from "@/components/Packages2.vue";
// import MainHeader2 from "@/components/MainHeader2.vue";
import ContactUs2 from "@/components/ContactUs2.vue";
import SomeOfDesigns2 from "@/components/SomeOfDesigns2.vue";
import Advantages2 from "@/components/Advantages2.vue";
import Footer from "@/components/Footer3.vue";
import OurServices from "@/components/OurServices.vue";
// import Marketers from "@/components/Marketers.vue";

export default {
  components: {
    Packages2,
    // MainHeader2,
    ContactUs2,
    SomeOfDesigns2,
    Advantages2,
    NavBar,
    Footer,
    OurServices,
    // Marketers,
  },
  mounted() {
    this.$router.push("#home");
  },
};
</script>
