<template>
  <nav class="w-full relative">
    <img
      src="@/assets/images/bg-nav.png"
      alt=""
      class="absolute top-0 transform -rotate-90 right-0"
    />
    <div class="z-40">
      <!-- <div class="">
        <div class="flex justify-between">
          <div
            class="flex justify-between items-center w-full md:hidden"
            :class="{ hidden: open === true }"
          >
            <div
              class="flex justify-around items-center rounded-bl-main-2 w-1/2 -auto py-6 px-3"
            >
              <button
                class="z-50"
                type="button"
                aria-controls="mobile-menu"
                aria-expanded="false"
                @click="open = true"
                :class="{ hidden: open === true }"
              >
                <div class="" style="width: 35px; height: 35px">
                  <img src="@/assets/images/menu-icon-24.png" alt="" />
                </div>
              </button>
            </div>
            <div>
              <div class="w-48 p-3">
                <img src="@/assets/images/logo2.png" alt="" />
              </div>
            </div>
          </div>
          <div
            class="flex-1 flex items-center justify-center md:items-stretch md:justify-start"
          >
            <div class="hidden md:block md:ml-6 w-full">
              <div class="flex items-center w-full">
                <button
                  id="pp"
                  class="text-center bg-white"
                  style="
                    padding: 8px 16px;
                    width: 150px;

                    font-weight: 400;
                    font-size: 16px;
                    border: 1px solid #2f1499;
                    color: #2f1499;
                  "
                >
                  تسجيل الدخول
                </button>
                <div class="hidden md:block w-full">
                  <div
                    class="flex justify-between rounded-bl-main-2 w-full py-6 px-3"
                    style="color: #1a1a1a80"
                  >
                    <div
                      class="w-3/4 flex flex-row-reverse justify-between mx-auto"
                    >
                      <div
                        class="px-1 py-2 rounded-md lg:text-base text-xs font-bold"
                      >
                        الرئيسية
                      </div>

                      <div
                        class="px-1 py-2 rounded-md lg:text-base text-xs font-bold"
                      >
                        معلومات عنا
                      </div>

                      <div
                        class="px-1 py-2 rounded-md lg:text-base text-xs font-bold"
                      >
                        خدماتنا
                      </div>
                      <div
                        class="px-1 py-2 rounded-md lg:text-base text-xs font-bold"
                      >
                        الاسعار
                      </div>
                      <div
                        class="px-1 py-2 rounded-md lg:text-base text-xs font-bold"
                      >
                        تواصل معنا
                      </div>
                      <div
                        class="px-1 py-2 rounded-md lg:text-base text-xs font-bold"
                      >
                        المؤثرون / المسوقون
                      </div>
                    </div>

                    <div>
                      <div class="" style="width: 150px">
                        <img src="@/assets/images/logo2.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Mobile menu, show/hide based on menu state. -->

      <!-- <div
        class="md:hidden absolute flex w-full z-30"
        id="mobile-menu"
        v-if="open"
      >
        <div
          class="px-2 pt-2h pb-3 space-y-1 w-full flex justify-between items-start"
        >
          <div class="flex flex-col justify-between rounded-bl-main-2 w-full">
            <div
              class="mb-3 px-1 py-2 rounded-md text-sm font-bold"
              style="width: fit-content"
              @click="open = !open"
            >
              الرئيسية
            </div>

            <div
              class="mb-3 px-1 py-2 rounded-md text-sm font-bold"
              style="width: fit-content"
              @click="open = false"
            >
              معلومات عنا
            </div>

            <div
              class="mb-3 px-1 py-2 rounded-md text-sm font-bold"
              style="width: fit-content"
              @click="open = false"
            >
              خدماتنا
            </div>
            <div
              class="mb-3 px-1 py-2 rounded-md text-sm font-bold"
              style="width: fit-content"
              @click="open = false"
            >
              الاسعار
            </div>
            <div
              class="mb-3 px-1 py-2 rounded-md text-sm font-bold"
              style="width: fit-content"
              @click="open = false"
            >
              تواصل معنا
            </div>
            <div
              class="mb-3 px-1 py-2 rounded-md text-sm font-bold"
              style="width: fit-content"
              @click="open = false"
            >
              المؤثرون / المسوقون
            </div>
          </div>
          <button @click="open = false" class="">X</button>
        </div>
      </div> -->

      <nav-bar-3 />

      <div class="mt-16 relative mx-auto" style="width: fit-content">
        <div
          class="text-center px-6 sm:flex pt-11 justify-center"
          style="font-weight: 400; font-size: 42px"
        >
          <div class="mb-2">
            <img
              src="@/assets/images/🦆 emoji _rocket_.png"
              alt=""
              class=""
              style="width: 50px; height: 50px"
            />
          </div>

          <div class="flex md:px-11 items-center" style="width: fit-content">
            <div class="mx-1 sm:w-48 w-24 h-5 sm:h-11">
              <img
                src="@/assets/images/main-title.png"
                class="object-contain"
                alt=""
              />
            </div>

            <div
              class="lg:text-4xl md:text-3xl text-2xl font-custom"
              style="font-weight: 400"
            >
              ابنى موقعك بكل سهولة مع
            </div>
          </div>

          <div class="sm:mt-16 mt-1 flex justify-end">
            <img
              src="@/assets/images/🦆 emoji _money with wings_.png"
              alt=""
              class="mx-1"
              style="width: 50px; height: 50px"
            />
          </div>
        </div>

        <div
          class="text-center mt-8 sm:text-2xl text-lg"
          style="color: #64748b; font-weight: 300"
        >
          ابدأ بعرض منتجاتك او خدماتك على متجرك الخاص وتطبيقك الخاص وضاعف قاعدة
          عملائك وارباحك وحقق نجاحك
        </div>
        <a href="https://merchant.storesy.com/en/signup" target="_blank">
          <button
            class="text-white font-medium sm:px-14 px-7 sm:py-3 py-2 rounded-md mt-6 flex mx-auto items-center"
            style="
              background: linear-gradient(180deg, #5460fc 0%, #2f1499 100%);
            "
          >
            انشئ متجرك الان
          </button>
        </a>
      </div>

      <div class="bg-ser">
        <div class="w-3/4 mx-auto relative">
          <img src="@/assets/images/main-header.png" alt="" />
        </div>
      </div>
      <img
        src="@/assets/images/Union-1.png"
        alt=""
        class="absolute top-1/2 right-0 h-1/3"
      />
    </div>
  </nav>
</template>

<script>
import NavBar3 from "@/components/NavBar3.vue";

export default {
  components: { NavBar3 },
  data() {
    return {
      open: false,
    };
  },
};
</script>
<style>
.bg-nav {
  background-image: url("/images/bg-nav.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 20px;
}
.bg-ser {
  background-image: url("/images/bg-ser.png");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .font-custom {
    font-size: 20px !important;
  }
}
</style>
