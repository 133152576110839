<template>
  <section class="">
    <nav id="navbar" class="">
      <div class="flex px-6 main-nav justify-between items-center py-6">
        <div
          class="flex items-center justify-between q-nav-w"
          style="width: 100%"
        >
          <a href="https://merchant.storesy.com/en/signin" target="_blank">
            <button
              class="px-4 py-2 text-center bg-white"
              style="
                font-weight: 400;
                font-size: 12px;
                border: 1px solid #2f1499;
                color: #2f1499;
              "
            >
              تسجيل الدخول
            </button>
          </a>
          <ul
            class="w-full flex justify-between mx-auto lg:text-lg text-sm flex-row-reverse"
            style="width: 70%"
          >
            <a
              v-for="(item, i) in navItems"
              :key="i"
              :href="item.link"
              :name="item.name"
              :class="{
                active2: item.link == $route.hash,
              }"
              @click="makeActive(item)"
              style="color: #64748b"
            >
              <li>
                {{ item.name }}
              </li>
            </a>
          </ul>
        </div>

        <div class="px-3" style="width: 200px">
          <img src="@/assets/images/logo2.png" alt="" />
        </div>
      </div>

      <div class="nav-small2 w-full py-6">
        <div class="flex justify-around px-11">
          <div
            class="z-50"
            style="width: 35px; height: 35px; cursor: pointer"
            @click="open = !open"
          >
            <img src="@/assets/images/menu-icon-24.png" alt="" />
          </div>
          <div
            class="p-3 flex ml-auto q-logo1"
            style="width: 200px; border: 2px solid #d3d3d3; border-radius: 11px"
          >
            <img
              src="@/assets/images/logo2.png"
              alt=""
              style=""
              class="object-contain"
            />
          </div>
        </div>
      </div>
      <div v-show="open" class="absolute z-30 w-full bg-white">
        <div class="w-full">
          <div
            class="flex justify-between w-full py-6 px-3"
            style="color: #64748b"
          >
            <div class="w-3/4 justify-between mx-auto">
              <ul v-for="(item, i) in navItems" :key="i" class="w-ul-nav">
                <a
                  :href="item.link"
                  :name="item.name"
                  @click="makeActive(item)"
                  style="width: fit-content"
                >
                  <li
                    class="mb-8 lpx-11"
                    style="width: fit-content"
                    @click="open = false"
                    :class="{
                      active4: item.link == $route.hash,
                    }"
                  >
                    {{ item.name }}
                  </li>
                </a>
              </ul>
            </div>
          </div>
          <a href="https://merchant.storesy.com/en/signup" target="_blank">
            <button
              class="text-center bg-white flex mx-auto mb-6"
              style="
                padding: 8px 26px;

                font-weight: 400;
                font-size: 22px;
                border: 1px solid #2f1499;
                color: #2f1499;
              "
            >
              تسجيل الدخول
            </button>
          </a>
        </div>
      </div>
    </nav>
  </section>
</template>
<script>
export default {
  data() {
    return {
      open: false,
      navItems: [
        { name: "الصفحة الرئيسية ", link: "#home", isActive: true },
        { name: " معلومات عنا", link: "#information", isActive: false },
        { name: "خدماتنا", link: "#services", isActive: false },
        { name: "الاسعار", link: "#packages", isActive: false },
        { name: "تواصل معنا", link: "#support", isActive: false },
        // { name: "المؤثرون / المسوقون", link: "#marketers", isActive: false },
      ],
    };
  },
  methods: {
    makeActive(item) {
      this.$router.push(item.link);

      // for (let index = 0; index < this.navItems.length; index++) {
      //   const element = this.navItems[index];
      //   if (item.link == element.link) {
      //     element.isActive = true;
      //   } else {
      //     element.isActive = false;
      //   }
      // }
    },
  },
};
</script>
<style>
.nav-small2 {
  display: none !important;
  border: 2px solid #2f1499;
  width: 100%;
  border-radius: 18px;
}
@media (max-width: 1024px) {
  .q-nav-w {
    width: 100% !important;
  }
  .q-nav-w button {
    margin-left: 1px;
    margin-right: 1px;
    height: 35px !important;
    width: 120px !important;
  }
  .q-logo1 {
    width: 160px !important;
  }
  .q-navItems {
    width: 70% !important;
  }
}
@media (max-width: 769px) {
  .main-nav {
    display: none !important;
  }
  .nav-small2 {
    display: block !important;
    transition: all 0.15s ease;
  }
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  position: relative;
  color: black;
  transition: all 0.15s ease;
}

a.active2:after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -11px;
  left: 0px;
  right: 0px;
  width: 80%;
  background: #2f1499;
  transition: all 0.15s ease;
}

a.active2 {
  color: #2f1499 !important;
}
.w-ul-nav a.active2:after {
  width: 50% !important;

  width: 26% !important;
}
.active4 {
  border-bottom: solid 2px;
  color: #2f1499 !important;
}
</style>
