<template>
  <section class="container mx-auto">
    <div>
      <p class="mx-auto w-2/5 font-bold text-center" style="font-size: 64px">
        مقياس العُلو للتفكير الحاسوبي
      </p>
      <p
        class="mx-auto w-2/5 font-medium mt-14 text-center"
        style="font-size: 18px"
      >
        الريادة في تقديم الاستشارات والخدمات التعليمية والفنية، وفق أفضل
        الممارسات والمعايير المحلية والعالمية.
      </p>
    </div>
    <div class="w-full my-6">
      <div class="flex mx-auto">
        <img src="/images/Image.svg" alt="" class="mx-auto my-6" />
      </div>
      <div class="flex justify-between my-7">
        <div class="text-right flex flex-col justify-center w-1/2">
          <div class="w-10/12 flex flex-col mx-auto">
            <div class="font-extrabold" style="font-size: 64px">عن المقياس</div>
            <div
              class="mx-auto font-medium mt-14 text-center"
              style="font-size: 24px; line-height: 4.5rem"
            >
              هو أول مقياس رقمي لمهارات التفكير الحاسوبي , تم تطويره بالكامل
              بقدرات سعودية , ليتناسب مع البيئة والثقافة العربية معتمدا في ذلك
              على النظرات العلمية , واخر ما توصلت له الدراسات والأبحاث
            </div>
          </div>
        </div>
        <div class="w-1/2">
          <img src="/images/Image(1).svg" alt="" class="mx-auto my-6" />
        </div>
      </div>
      <div class="flex justify-between">
        <div class="w-1/2">
          <img src="/images/Image(2).svg" alt="" class="mx-auto my-6" />
        </div>
        <div class="text-right flex flex-col justify-center w-1/2">
          <div class="w-10/12 flex flex-col mx-auto">
            <div class="font-extrabold" style="font-size: 64px">
              ما المقصود بالتفكير الحاسوبي
            </div>
            <div
              class="mx-auto font-medium mt-14 text-center"
              style="font-size: 24px; line-height: 4.5rem"
            >
              هو مجموعة من مهارات التفكير العليا التي تعد أساسية ينبغي توفرها
              لدى الجميع , حيث تساعد على حل المشكلات , تصميم الأنظمة , فهم
              السلوك البشري , وذلك بالاعتماذ على المفاهيم الأساسية لعلوم الحاسب
              الالي .
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="text-center font-bold" style="font-size: 64px">
          أغراض استخدام المقياس
        </p>
        <div v-for="(item, i) in items" :key="i">
          <img
            src="/images/primary-shapeprimary-shape.svg"
            alt=""
            class="mx-auto my-6"
          />
          {{ item }}
        </div>
      </div>
      <div class="flex justify-between">
        <div class="w-1/2">
          <img src="/images/Image(3).svg" alt="" class="mx-auto my-6" />
        </div>
        <div class="text-right flex flex-col justify-center w-1/2">
          <div class="w-10/12 flex flex-col mx-auto">
            <div class="font-extrabold" style="font-size: 64px">
              الفئة المستهدفة
            </div>
            <div
              class="mx-auto font-medium mt-14 text-center"
              style="font-size: 24px; line-height: 4.5rem"
            >
              يمكن استخدام المقياس لكافة الفئات العمرية , كما لا يشترط وجود
              خلفية معرفة في مجال علوم الحاسوب
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        "قياس الميول نحو تخصصات علوم الحاسوب وفروعه",
        "فرز المرشحين للبرامج في مجال علوم الحاسوب وفروعه",
        "قياس اثر البرامج , والبحوث العلمية التي تهدف لتنمية مهارات التفكير الحاسوبي",
        "استكشاف الموهوبين",
      ],
    };
  },
};
</script>
