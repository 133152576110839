import axios from "axios";
import store from "@/store";

const request = axios.create({
  baseURL: "https://api.storesy.com/api/v1",
});

request.interceptors.request.use(function (config) {
  const token = store.getters.token;

  // Do something before request is sent
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
export default request;
