<template>
  <section class="py-11 text-right relative mb-12">
    <div class="absolute bottom-0 right-0">
      <img src="@/assets/images/bgbakages2.png" alt="" class="" />
    </div>
    <div
      class="text-center custom-w sm:text-5xl text-3xl mx-auto px-3"
      style=""
    >
      ميزات متكاملة وكاملة
      <div class="my-5 text-center" style="color: #2f1499">
        للمبدعين الأقوياء
      </div>
      <div class="mx-auto" style="font-size: 20px">اختر خطة تناسبك</div>
      <div class="flex mt-16 mx-auto justify-end" style="font-size: 16px">
        <div>الدفع شهريا</div>

        <label class="switch mx-3">
          <input @change="getValue(check)" v-model="check" type="checkbox" />
          <span class="slider round"></span>
        </label>

        <div>ادفع سنويا</div>
        <figure class="">
          <img src="@/assets/images/free.png" alt="" class="w-32 h-20" />
        </figure>
      </div>
    </div>
    <div class="flex flex-wrap justify-center mt-6">
      <div
        v-for="(item, i) in packages"
        :key="i"
        class="p-3 pb-16 mb-11 q-packages-card sm:mx-6 mx-auto w-1/4"
        style="border-radius: 4px; border: 1px solid rgba(34, 178, 170, 0.34)"
        :class="{ 'bg-special': i == 1 }"
      >
        <div
          class="pt-6 px-3"
          style="font-weight: 700; font-size: 22px"
          :class="{ 'text-white': i == 1 }"
        >
          <!-- الباقة المميزة -->
          {{ item.name }}
        </div>
        <div
          class="pt-6 px-3"
          :class="{ 'color-spec': i == 1 }"
          style="color: #64748b"
        >
          أوفر باقة لأصحاب الشركات الصغيرة
        </div>

        <div class="mt-3 mx-3">
          <div class="flex justify-end items-center">
            <div class="mx-2" :class="{ 'text-white': i == 1 }">شهريا /</div>

            <div
              class=""
              style="font-weight: 700; font-size: 56px"
              :class="{ 'text-white': i == 1 }"
            >
              <!-- $2000 -->
              ${{ item.price }}
            </div>
          </div>
          <a
            class="w-11/12"
            href="https://merchant.storesy.com/en/signin"
            target="_blank"
          >
            <button
              class="w-full my-6"
              style="
                padding: 6px 12px;
                border-radius: 4px;
                color: #2f1499;
                border: 1px solid #5460fc;
              "
              :class="{ 'bg-white': i == 1 }"
            >
              ابدأ الآن
            </button>
          </a>
        </div>
        <div :class="{ 'text-white': i == 1 }">
          <div
            class="text-center"
            v-for="(feature, i) in item.features"
            :key="i"
          >
            <div class="flex mb-4 justify-end">
              <div class="mx-1">{{ feature }}</div>
              <div>
                <img
                  src="@/assets/images/Frame75.png"
                  alt=""
                  class=""
                  style="width: 32px; height: 32px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        class="p-3 pb-16 mb-11 mx-6 w-1/4 q-packages-card"
        style="
          background: linear-gradient(180deg, #5460fc 0%, #2f1499 100%);
          border-radius: 16px;
        "
      >
        <div
          class="pt-6 px-3 text-white"
          style="font-weight: 700; font-size: 22px"
        >
          باقة الشركات
        </div>
        <div class="pt-6 px-3 text-white">
          مثالي للأفراد الذين يحتاجون إلى ميزات وأدوات متقدمة لعمل العميل.
        </div>

        <div class="my-6 pb-6 mx-3">
          <div class="flex justify-end items-center">
            <div class="mx-2 text-white">شهريا /</div>

            <div class="text-white" style="font-weight: 700; font-size: 56px">
              $3500
            </div>
          </div>
          <a class="w-11/12" href="https://sobe.storesy.com/en" target="_blank">
            <button
              class="bg-white w-full"
              style="padding: 6px 12px; border-radius: 4px; color: #2f1499"
            >
              ابدأ الآن
            </button>
          </a>
        </div>
        <div>
          <div
            class="text-center text-white"
            v-for="(item, i) in items"
            :key="i"
          >
            <div class="flex mb-4 justify-end">
              <div class="mx-1">{{ item.name }}</div>
              <div>
                <img
                  src="@/assets/images/Frame75.png"
                  alt=""
                  class=""
                  style="width: 32px; height: 32px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-3 pb-16 mb-11 mx-6 w-1/4 q-packages-card"
        style="border-radius: 4px; border: 1px solid rgba(34, 178, 170, 0.34)"
      >
        <div class="pt-6 px-3" style="font-weight: 700; font-size: 22px">
          الباقة الشخصية
        </div>
        <div class="pt-6 px-3 mb-11" style="color: #64748b">
          نمي علامتك التجارية بموقع احترافي
        </div>

        <div class="my-6 pb-6 mx-3">
          <div class="flex justify-end items-center">
            <div class="mx-2">شهريا /</div>
            <div class="" style="font-weight: 700; font-size: 56px">$1500</div>
          </div>
          <a class="w-11/12" href="https://sobe.storesy.com/en" target="_blank">
            <button
              class="w-full"
              style="
                padding: 6px 12px;
                border-radius: 4px;
                color: #2f1499;
                border: 1px solid #5460fc;
              "
            >
              ابدأ الآن
            </button>
          </a>
        </div>
        <div>
          <div class="text-center" v-for="(item, i) in items2" :key="i">
            <div class="flex mb-4 justify-end">
              <div class="mx-1">{{ item.name }}</div>
              <div>
                <img
                  src="@/assets/images/Frame75.png"
                  alt=""
                  class=""
                  style="width: 32px; height: 32px"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>
<script>
import request from "@/plugins/request";

export default {
  data() {
    return {
      check: false,
      items: [
        { name: "دعم في شرح وتحسين محركات البحث (seo)" },
        { name: "مساعدة في إضافة جوجل تاج مانجر، تحليلات جوجل وفيسبوك بيكسل" },
        { name: "ساعة من الدعم الفني المميز" },
        { name: "عدد لغات غير محدود" },
        { name: "عدد زوار غير محدود" },
        { name: "مساحة تخزين غير محدودة" },
        { name: "كل مميزات الباقة المميزة" },
      ],
      items2: [
        { name: "ربط النطاق الخاص بك" },
        { name: "مساحة تخزين 3 جيجابايت" },
        { name: "عدد زوار بحجم 2 جيجابايت" },
        { name: "لغة أساسية + لغة إضافية" },
        { name: "إزالة إعلانات ويلت" },
        { name: "الدعم الفني (عبر الدردشة)" },
        { name: "شهادة حماية SSL" },
      ],
      items3: [
        { name: "كل مميزات الباقة الشخصية" },
        { name: "نطاق مجاني (.com) لمدة عام" },
        { name: "مساحة تخزين 10 جيجا" },
        { name: "لغة أساسية + ٢ لغات إضافية" },
        { name: "عدد زوار غير محدود" },
        { name: "دعم عبر الهاتف والدردشة" },
      ],
      packages: [],
    };
  },
  mounted() {
    this.getPackages();
  },
  methods: {
    getValue(value) {
      console.log("value", value);
    },
    getPackages() {
      request.get("/packages").then((data) => {
        this.packages = data.data.data;
        console.log(this.packages);
      });
    },
  },
};
</script>
<style>
.custom-w {
  width: 470px;
}
@media (max-width: 480px) {
  .custom-w {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .q-packages-card {
    width: 40% !important;
  }
  .q-margin {
    margin-left: 10% !important;
  }
}
@media (max-width: 768px) {
  .q-packages-card {
    width: 70% !important;
  }
  .q-margin {
    margin-left: 10% !important;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2f1499;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2f1499;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.bg-special {
  background: linear-gradient(180deg, #5460fc 0%, #2f1499 100%);
}
.color-spec {
  color: #f7f8f9 !important;
}
@media (max-width: 480px) {
  .q-packages-card {
    width: 90% !important;
  }
}
</style>
