<template>
  <section class="bg-cont pb-16">
    <div class="flex q-contact-form">
      <div class="sm:px-11 px-3 text-center mx-auto" style="">
        <div
          class="text-white my-6 q-text-cont"
          style="font-weight: 700; font-size: 42px"
        >
          تواصل معنا لتسهيل جميع امورك
        </div>

        <div
          class="text-white w-2/3 mx-auto"
          style="font-weight: 300; font-size: 16px"
        >
          يمكنك مراسلتنا في أي وقت تريد من نموذج التواصل أدناه أو الاتصال بنا
          على الرقم الموحد 8001111210 (من الأحد إلى الخميس من 9 صباحاً وحتى 5
          مساءاً) إذا واجهتك مشكلة في الدفع مع إحدى المتاجر على المنصة، اضغط
          لتقديم شكوى
        </div>
        <form
          action="#"
          onsubmit="event.preventDefault()"
          name="submit-to-google-sheet"
          class="space-y-8 bg-transparent sm:w-3/4 w-full mx-auto mt-11"
        >
          <div class="flex w-full justify-between flex-wrap">
            <div class="w-1/2 px-2 mb-6">
              <input
                placeholder="البريد الالكترونى"
                v-model="email"
                name="email"
                class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
              />
            </div>
            <div class="w-1/2 px-2 mb-6">
              <input
                placeholder="الاسم"
                v-model="name"
                name="name"
                class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
              />
            </div>

            <div class="w-1/2 px-2 mb-6">
              <input
                placeholder="نوع الرسالة"
                v-model="typeMessage"
                name="type Message"
                class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
              />
            </div>
            <div class="w-1/2 px-2 mb-6">
              <input
                placeholder="رقم الجوال"
                v-model="mobile"
                name="mobile"
                class="border h-12 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
              />
            </div>

            <div class="w-full px-2 mb-6">
              <textarea
                rows="6"
                cols="50"
                v-model="message"
                name="message"
                placeholder="محتوى الراسلة"
                class="border z-30 rounded-md flex items-start border-gray-300 bg-transparent text-sm text-right w-full p-2"
              >
              </textarea>
            </div>

            <!-- <div
              class="w-1/2 px-2 mb-6"
              :class="{ 'h-24 w-full': i == 4 }"
              v-for="(item, i) in items"
              :key="i"
            >
              <div class="relative bg-transparent mb-1">
                <label
                  v-if="!item.dataName"
                  class="block absolute bg-transparent bottom-0 focus-style-label right-0 mx-3 my-1 text-gray-300 mb-1 font-medium"
                  :class="{
                    'transform -translate-y-5 translate-x-2':
                      item.active == true,
                    'top-0 ': i == 4,
                  }"
                  style="height: fit-content; width: fit-content"
                >
                  {{ item.name }}
                </label>
                <textarea
                  v-if="i == 4"
                  rows="6"
                  cols="50"
                  v-model="item.dataName"
                  class="border z-30 rounded-md flex items-start focus-style border-gray-300 bg-transparent text-sm text-right w-full p-2"
                  @focus="focusInput(item.type)"
                  required
                  @focusout="unFocusInput(item.type)"
                  @input="getData(item)"
                >
                </textarea>
                <input
                  v-else
                  v-model="item.dataName"
                  class="border z-30 rounded-md flex items-start focus-style border-gray-300 bg-transparent text-sm text-right w-full p-2"
                  @focus="focusInput(item.type)"
                  required
                  @focusout="unFocusInput(item.type)"
                  @input="getData(item)"
                />
              </div>
            </div> -->
          </div>
          <!-- background-color: #3e0cd9; -->

          <button
            class="text-center bg-white my-3"
            style="
            padding: 2px 46px;

            font-weight: 400;
            font-size: 20px;
            color:#2F1499
            border-radius: 10px;
          "
            :class="{
              'cursor-not-allowed':
                !name || !email || !message || !mobile || !typeMessage,
            }"
            @click="submit()"
          >
            ارسال
          </button>
        </form>
        <!-- <div class="bg-transparent w-3/4 mx-auto mt-11">
          <div class="flex justify-between w-full bg-transparent">
            <validation-provider
              class="w-1/2 mx-6"
              rules="required|email"
              v-slot="{ errors }"
            >
              <input
                placeholder="البريد الالكترونى"
                class="bg-transparent p-3 text-right w-full mx-6"
                v-model="email"
                name="field"
                type="text"
                style="border: 0.5px solid #ffffff"
                rules="required"
              />
              <div class="text-red-500 h-1">{{ errors[0] }}</div>
            </validation-provider>
            <validation-provider
              class="w-1/2 mx-6"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                placeholder="الاسم"
                class="bg-transparent p-3 text-right w-full mx-6"
                style="border: 0.5px solid #ffffff"
                v-model="name"
                name="field"
                type="text"
                rules="required"
              />
              <div class="text-red-500 h-1">{{ errors[0] }}</div>
            </validation-provider>
          </div>

          <div class="flex justify-between my-6 bg-transparent">
            <validation-provider
              class="w-1/2 mx-6"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                placeholder="نوع الرسالة"
                class="bg-transparent p-3 text-right w-full mx-6"
                v-model="message"
                name="field"
                type="text"
                style="border: 0.5px solid #ffffff"
                rules="required"
              />
              <div class="text-red-500 h-1">{{ errors[0] }}</div>
            </validation-provider>
            <validation-provider
              class="w-1/2 mx-6"
              rules="required"
              v-slot="{ errors }"
            >
              <input
                placeholder="رقم الجوال"
                class="bg-transparent p-3 text-right w-full mx-6"
                style="border: 0.5px solid #ffffff"
                v-model="mobile"
                name="field"
                type="text"
                rules="required"
              />
              <div class="text-red-500 h-1">{{ errors[0] }}</div>
            </validation-provider>
          </div>
          <validation-provider
            class="w-full mx-6"
            rules="required"
            v-slot="{ errors }"
          >
            <textarea
              placeholder=" محتوى الرسالة  "
              class="bg-transparent w-full p-3 text-right mx-6"
              v-model="message"
              name="field"
              type="text"
              style="border: 0.5px solid #ffffff; height: 150px"
              rules="required"
            >
            </textarea>
            <div class="text-red-500 h-1">{{ errors[0] }}</div>
          </validation-provider>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      mobile: "",
      typeMessage: "",
      items: [
        {
          name: "البريد الالكترونى",
          dataName: "",
          type: "email",
          active: false,
        },
        {
          name: "الاسم",
          dataName: "",
          type: "name",
          active: false,
        },

        {
          name: "رقم الجوال",
          dataName: "",
          type: "mobile",
          active: false,
        },
        {
          name: "نوع الرسالة",
          dataName: "",
          type: "typeMessage",
          active: false,
        },
        {
          name: "محتوى الراسلة",
          dataName: "",
          type: "message",
          active: false,
        },
      ],
    };
  },
  methods: {
    focusInput(type) {
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (type == element.type) {
          element.active = true;
        } else {
          element.active = false;
        }
      }
    },
    unFocusInput(type) {
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (type == element.type) {
          element.active = false;
        } else {
          element.active = false;
        }
      }
    },
    getData(data) {
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (data.type == "name") {
          this.name = data.dataName;
        } else if (data.type == "email") {
          this.email = data.dataName;
        } else if (data.type == "typeMessage") {
          this.typeMessage = data.dataName;
        } else if (data.type == "mobile") {
          this.mobile = data.dataName;
        }
      }
    },
    submit() {
      if (
        this.name &&
        this.email &&
        this.message &&
        this.mobile &&
        this.typeMessage
      ) {
        const scriptURL =
          "https://script.google.com/macros/s/AKfycby_nGGWVzIRdaYv-FafKr3VmaF-oyaQGJAfyU6jgNJMQnwmRVuHKVGvZqJPTaFbnAmJ/exec";
        const form = document.forms["submit-to-google-sheet"];

        // e.preventDefault();
        fetch(scriptURL, { method: "POST", body: new FormData(form) })
          .then((response) => {
            if (response.ok) {
              this.$toasted.show("successfully added..!")((this.name = "")),
                (this.email = ""),
                (this.message = ""),
                (this.mobile = ""),
                (this.typeMessage = "");
            }
          })
          .catch(
            (error) => console.error("Error!", error.message)
            // this.$toasted.show("Error..!")
          );
      }
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  .q-contact-desc {
    display: flex;
    flex-direction: column;
  }
  .q-contact-form {
    display: flex;
    flex-direction: column;
  }
  .q-contact-form div {
    width: 100%;
  }
  .q-text-cont {
    font-size: 36px !important;
  }
}
@media (max-width: 480px) {
  .q-text-cont {
    font-size: 26px !important;
  }
  .q-contact-form {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1200px) {
  .q-contact-desc {
    display: flex !important;
    flex-direction: column !important;
  }
  .q-contact-desc-box {
    width: 100% !important;
    justify-content: space-between !important;
  }
  .q-contact-desc-box-1 {
    width: 100% !important;
    margin-top: 11px;
    justify-content: center !important;
  }
}
@media (max-width: 1024px) {
  .q-contact-desc {
    display: flex !important;
    flex-direction: column !important;
  }
  .q-contact-desc-box {
    display: flex !important;
    flex-direction: column !important;
  }
  .q-contact-desc-box-1 {
    justify-content: right !important;
  }
}
.bg-cont {
  background-image: url("/images/BG.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.focus-style-label {
  transition: all 125ms;
}
</style>
