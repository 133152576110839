var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('nav',{attrs:{"id":"navbar"}},[_c('div',{staticClass:"flex px-6 main-nav justify-between items-center py-6"},[_c('div',{staticClass:"flex items-center justify-between q-nav-w",staticStyle:{"width":"100%"}},[_vm._m(0),_c('ul',{staticClass:"w-full flex justify-between mx-auto lg:text-lg text-sm flex-row-reverse",staticStyle:{"width":"70%"}},_vm._l((_vm.navItems),function(item,i){return _c('a',{key:i,class:{
              active2: item.link == _vm.$route.hash,
            },staticStyle:{"color":"#64748b"},attrs:{"href":item.link,"name":item.name},on:{"click":function($event){return _vm.makeActive(item)}}},[_c('li',[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)]),_vm._m(1)]),_c('div',{staticClass:"nav-small2 w-full py-6"},[_c('div',{staticClass:"flex justify-around px-11"},[_c('div',{staticClass:"z-50",staticStyle:{"width":"35px","height":"35px","cursor":"pointer"},on:{"click":function($event){_vm.open = !_vm.open}}},[_c('img',{attrs:{"src":require("@/assets/images/menu-icon-24.png"),"alt":""}})]),_vm._m(2)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"absolute z-30 w-full bg-white"},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex justify-between w-full py-6 px-3",staticStyle:{"color":"#64748b"}},[_c('div',{staticClass:"w-3/4 justify-between mx-auto"},_vm._l((_vm.navItems),function(item,i){return _c('ul',{key:i,staticClass:"w-ul-nav"},[_c('a',{staticStyle:{"width":"fit-content"},attrs:{"href":item.link,"name":item.name},on:{"click":function($event){return _vm.makeActive(item)}}},[_c('li',{staticClass:"mb-8 lpx-11",class:{
                    active4: item.link == _vm.$route.hash,
                  },staticStyle:{"width":"fit-content"},on:{"click":function($event){_vm.open = false}}},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0)]),_vm._m(3)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://merchant.storesy.com/en/signin","target":"_blank"}},[_c('button',{staticClass:"px-4 py-2 text-center bg-white",staticStyle:{"font-weight":"400","font-size":"12px","border":"1px solid #2f1499","color":"#2f1499"}},[_vm._v(" تسجيل الدخول ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3",staticStyle:{"width":"200px"}},[_c('img',{attrs:{"src":require("@/assets/images/logo2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3 flex ml-auto q-logo1",staticStyle:{"width":"200px","border":"2px solid #d3d3d3","border-radius":"11px"}},[_c('img',{staticClass:"object-contain",attrs:{"src":require("@/assets/images/logo2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://merchant.storesy.com/en/signup","target":"_blank"}},[_c('button',{staticClass:"text-center bg-white flex mx-auto mb-6",staticStyle:{"padding":"8px 26px","font-weight":"400","font-size":"22px","border":"1px solid #2f1499","color":"#2f1499"}},[_vm._v(" تسجيل الدخول ")])])
}]

export { render, staticRenderFns }