<template>
  <section class="bg-our-ser py-4">
    <div class="">
      <div
        class="text-center justify-center items-center flex px-3 mb-11 mx-auto"
      >
        <div>
          <img src="@/assets/images/🦆 emoji _glowing star_.png" alt="" />
        </div>
        <div class="mx-2 sm:text-5xl text-3xl" style="">خدماتنا بين يديك</div>
      </div>
      <div class="flex q-card-ser justify-center flex-wrap">
        <div v-for="(item, i) in items" :key="i" class="w-1/3 card mb-11">
          <div
            class="flex mx-3"
            style="
              background: #ffffff;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
              border-radius: 16px;
              padding: 0px 0px 0px 8px;
            "
          >
            <div class="flex-col justify-center text-right">
              <div class="flex flex-custom justify-end items-center py-4">
                <div class="w-2/3 w-custom mr-2 py-3">
                  <div class="my-3">{{ item.title }}</div>
                  <div class="text-sm" style="color: #1a1a1a; opacity: 0.7">
                    {{ item.desc }}
                  </div>
                </div>

                <div class="w-56 h-56" style="">
                  <img
                    :src="item.img"
                    alt=""
                    class="w-full object-contain h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          title: "سهولة بناء متجرك",
          desc: "مع سلة تستطيع خلال دقائق إنشاء متجرك الخاص بأقل التكاليف والحصول على استضافة مجانية وتحديثات مستمرة ومتجددة وبدون أي عمولة على المبيعات",
          img: "images/easy-build.png",
        },
        {
          title: "ضع منجاتك بكل سهولة",
          desc: "ستتمكن من إدارة منتجاتك، مهما كان نوع هذه المنتجات سواءاً منتجات جاهزة أو حسب الطلب أو منتجات رقمية وغيرها بكل سهولة",
          img: "images/User-flow-pana.png",
        },
        {
          title: "طرق دفع مناسبه لك",
          desc: "في سلة نوفر لك جميع وسائل الدفع بدءاً من التحويل البنكي والدفع عند الاستلام والدفع بالبطاقات الائتمانية (فيزا وماستر كارد) والدفع بواسطة آبل باي وكذلك باي بال PayPal",
          img: "images/E-Wallet-rafiki.png",
        },
        {
          title: "افضل الأدوات لتسهيل التسويق",
          desc: "حرصنا من تمكين التجار من التسويق بشكل قوي وبأدوات سهلة وبسيطة. حيث يمكنك عمل حملات تسويقية وإرسالها للعملاء مع تحديد الشريحة المستهدفة بإحترافية وسهولة",
          img: "images/Design-stats-bro.png",
        },
        {
          title: "تصاميم تناسب زوقك",
          desc: "تصميم المتجر ومظهره هو ما يكوّن أول انطباع لدى العميل عن مدى احترافية القائمين على المتجر. لذلك وفرنا لكم تشيكلة من التصاميم عالية الاحترافية",
          img: "images/Prototyping-process-bro.png",
        },
      ],
    };
  },
};
</script>

<style>
@media (max-width: 1024px) {
  .q-card-ser .card {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .q-card-ser .card {
    width: 90%;
  }
}
.bg-our-ser {
  background-image: url("/images/Group 6356095.png");
  background-size: 100%;

  background-repeat: no-repeat;
}
.green-bg {
  background-image: url("/images/green-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .flex-custom {
    flex-direction: column-reverse !important;
  }
  .w-custom {
    width: 90% !important;
  }
}
</style>
