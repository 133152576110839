import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/sass/main.scss";
import "@/plugins/request.js";
import Toasted from "vue-toasted";

Vue.use(Toasted, {
  duration: 1000,
  position: "bottom-right",
  action: {
    text: "Okay",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
