<template>
  <section class="p-11 pb-3" style="border-radius: 32px 32px 0px 0px">
    <div class="md:flex-row flex flex-col-reverse justify-center">
      <div class="md:flex">
        <div class="flex-col md:w-72 w-full flex text-right">
          <div class="mb-6" style="color: #2563eb">طرق الدفع</div>

          <div class="flex flex-row-reverse mb-6">
            <div
              class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
              style="border: 1px solid rgba(228, 228, 228, 0.6)"
            >
              <img class="" src="@/assets/images/mony.png" alt="" />
            </div>
            <div
              class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
              style="border: 1px solid rgba(228, 228, 228, 0.6)"
            >
              <img class="" src="@/assets/images/visa.png" alt="" />
            </div>
            <div
              class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
              style="border: 1px solid rgba(228, 228, 228, 0.6)"
            >
              <img class="" src="@/assets/images/am_ex.png" alt="" />
            </div>
            <div
              class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
              style="border: 1px solid rgba(228, 228, 228, 0.6)"
            >
              <img class="" src="@/assets/images/paypal.png" alt="" />
            </div>
            <div
              class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
              style="border: 1px solid rgba(228, 228, 228, 0.6)"
            >
              <img class="" src="@/assets/images/Group4.png" alt="" />
            </div>
          </div>
          <!-- <div>
            <div class="mb-4">السجل التجاري : 654310384103</div>
            <div>الرقم الضريبي : 63854103.41503.52</div>
          </div> -->
          <!-- <button
        @click="top()"
        class="fixed bottom-2 left-0 mr-auto focus:outline-none"
      >
        <img src="@/assets/images/arrow-up.png" alt="" class="w-11 h-11 mx-4" />
      </button> -->
        </div>
        <div
          class="flex-col flex md:w-40 w-full lg:mx-32 md:mx-4 mx-0 justify-between text-right"
        >
          <div class="">
            <div class="w-32 ml-auto">
              <img src="@/assets/images/logo2.png" alt="" />
            </div>

            <div class="rounded-lg py-3l z-10" style="color: #52525b">
              <!-- <div>الصفحة الرئيسية</div>
              <div class="py-3">معلومات عنا</div>
              <div>خدامتنا</div>
              <div class="py-3">الاسعار</div>
              <div>تواصل معنا</div> -->

              <ul
                class="w-full flex flex-col justify-between mx-auto lg:text-lgtext-sm"
                style="width: 100%"
              >
                <a
                  v-for="(item, i) in navItems"
                  :key="i"
                  :href="item.link"
                  :name="item.name"
                  :class="{
                    active3: item.link == $route.hash,
                  }"
                  @click="makeActive(item)"
                  style="color: #64748b"
                >
                  <li class="mb-3">
                    {{ item.name }}
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-col flex text-right xs-w-f xl:w-80 md:w-72 q-f-w-full">
        <div>
          <div class="w-44 ml-auto">
            <img src="@/assets/images/logo2.png" alt="" />
          </div>
          <div class="" style="color: #52525b">
            منصة إنشاء متاجر إلكترونية هي أداة تسمح لأي شخص بإنشاء متجر إلكتروني
            بكل سهولة وبدون الحاجة إلى أي خبرة تقنية.
          </div>
          <div class="flex flex-row-reverse my-6">
            <a
              href="https://www.instagram.com/storesyofficial/"
              target="_blank"
            >
              <div
                class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
                style="border: 1px solid rgba(228, 228, 228, 0.6)"
              >
                <img class="" src="@/assets/images/instgrem.png" alt="" />
              </div>
            </a>

            <!-- <div
              class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
              style="border: 1px solid rgba(228, 228, 228, 0.6)"
            >
              <img class="" src="@/assets/images/Group.png" alt="" />
            </div> -->
            <a href="https://twitter.com/StoresyOfficial" target="_blabk">
              <div
                class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
                style="border: 1px solid rgba(228, 228, 228, 0.6)"
              >
                <img class="" src="@/assets/images/twitter.png" alt="" />
              </div>
            </a>

            <!-- <div
              class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
              style="border: 1px solid rgba(228, 228, 228, 0.6)"
            >
              <img class="" src="@/assets/images/pinterest.png" alt="" />
            </div> -->
            <a
              href="https://www.facebook.com/profile.php?id=100092996082074"
              target="_blabk"
            >
              <div
                class="bg-white px-2 justify-center w-12 h-12 flex items-center rounded-lg ml-2"
                style="border: 1px solid rgba(228, 228, 228, 0.6)"
              >
                <img class="" src="@/assets/images/facebook.png" alt="" />
              </div>
            </a>
          </div>
        </div>

        <!-- <button
        @click="top()"
        class="fixed bottom-2 left-0 mr-auto focus:outline-none"
      >
        <img src="@/assets/images/arrow-up.png" alt="" class="w-11 h-11 mx-4" />
      </button> -->
      </div>
    </div>

    <div class="border-b-2 w-full mt-11"></div>
    <div class="text-center mt-6" style="color: #71717a">
      ©️ 2023 algorithimia
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      open: false,
      navItems: [
        { name: "الصفحة الرئيسية ", link: "#home", isActive: true },
        { name: " معلومات عنا", link: "#information", isActive: false },
        { name: "خدماتنا", link: "#services", isActive: false },
        { name: "الاسعار", link: "#packages", isActive: false },
        { name: "تواصل معنا", link: "#support", isActive: false },
        // { name: "المؤثرون / المسوقون", link: "#marketers", isActive: false },
      ],
    };
  },
  methods: {
    makeActive(item) {
      this.$router.push(item.link);

      // for (let index = 0; index < this.navItems.length; index++) {
      //   const element = this.navItems[index];
      //   if (item.link == element.link) {
      //     element.isActive = true;
      //   } else {
      //     element.isActive = false;
      //   }
      // }
    },
  },
};
</script>
<style>
@media (min-width: 768px) {
  .xs-w-f {
    width: 450px !important;
  }
}
@media (max-width: 768px) {
  .q-f-w-full {
    width: 100% !important;
    margin: 0 !important;
  }
  .q-footer {
    display: flex;
    flex-direction: column-reverse;
  }
  .box-1 {
    width: 100% !important;
    margin-top: 2px;
  }
  .box-2 {
    width: 100% !important;
  }
  .margin-non {
    margin: unset !important;
  }
  @media (max-width: 480px) {
    .box-1 {
      display: flex;
      flex-direction: column;
    }
    .box-w-1 {
      width: 100% !important;
    }
    .box-w {
      width: 100% !important;
      margin-top: 11px;
      margin-bottom: 11px;
    }
  }
}

a {
  text-decoration: none;
  position: relative;
  color: black;
  transition: all 0.15s ease;
}

a.active3:after {
  /* position: absolute;
  content: "";
  height: 2px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 80%;
  background: #2f1499;
  transition: all 0.15s ease; */
}

a.active3 {
  color: #2f1499 !important;
}
.w-ul-nav a.active3:after {
  width: 50% !important;

  width: 26% !important;
}
</style>
