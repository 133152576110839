<template>
  <section>
    <div class="relative">
      <img
        src="@/assets/images/bgsomework.png"
        alt=""
        class="absolute bottom-0 right-0"
        style="height: 900px; width: 100%"
      />
      <div
        class="text-center justify-center items-center flex sm:px-3 mb-11 mx-auto"
      >
        <div class="sm:p-0 p-1">
          <img src="@/assets/images/🦆 emoji _sun with face_.png" alt="" />
        </div>
        <div class="sm:mx-2 q-text sm:text-5xl text-2xl" style="">
          بعض من متاجرنا الجذابة
        </div>
      </div>
      <carousel-3d
        :perspective="0"
        :space="500"
        :width="400"
        :height="450"
        :display="5"
      >
        <slide v-for="(slide, index) in items" :index="index" :key="index">
          <div style="border-radius: 16px" class="h-full w-full">
            <img :src="slide.img" alt="" class="object-cover h-full w-full" />
          </div>
        </slide>
      </carousel-3d>
    </div>
  </section>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  components: { Carousel3d, Slide },
  data() {
    return {
      slides: 7,
      items: [
        { img: "images/des1.png" },
        { img: "images/des2.png" },
        { img: "images/store.png" },
        { img: "images/store.png" },
        { img: "images/des1.png" },

        { img: "images/des2.png" },
        { img: "images/des1.png" },

        { img: "images/store.png" },
      ],
    };
  },
};
</script>
<style scoped>
.carousel-3d-slide {
  background: transparent;
  border: 0px;
}
@media (max-width: 768px) {
  .q-text {
    font-size: 30px !important;
  }
}
@media (max-width: 480px) {
  .q-text {
    font-size: 25px !important;
  }
}
</style>
